import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'

class Post extends Component {

  render() {
    let { post } = this.props.data

    return (
      <>
        <SEO bodyClass={'page--post'} title={ post.title } keywords={[`gatsby`, `application`, `react`]} />
        <div className='post container'>
          <div className='col col-lrg-third post__categories'>
            { post.categories.map((cat, i) => <Link key={i} className='tag' to={`/news/${cat.slug}`}>{ cat.name }</Link>) }
            <br />
            <Link to='/news' className='post__backlink'>
              <svg xmlns="http://www.w3.org/2000/svg" width="14.168" height="11.734" viewBox="0 0 14.168 11.734">
                <path d="M318.049,461.584l.99-.99-4.177-4.177H326.35v-1.4H314.863l4.177-4.177-.99-.99-5.867,5.867Z" transform="translate(-312.182 -449.85)" fill="#707070"/>
              </svg>
              Back
            </Link>
          </div>
          <div className='col col-75 col-lrg-2thirds'>

            <h1>{ post.title }</h1>
            <div dangerouslySetInnerHTML={{__html: post.content }} />
          </div>
        </div>
      </>
    )
  }
}

export default Post

export const postQuery = graphql`
  query currentPostQuery($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      title
      slug
      content
      categories {
        slug
        name
      }
    }
  }
`
